// @flow

import React, { useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { UncontrolledPopover } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import itly from 'itly';
import moment from 'moment';

import { Account, BdRequest, Org, OrgUser, Partner, PartnerAccount } from 'data/entities';
import { BdRequestQuestion } from 'data/entities/bdrequestQuestion';
import { getBdRequestMessageSummary } from 'data/repositories/bdrequestQuestion';
import { createQuestionsBdRequest } from 'data/repositories/bdrequests';
import withOrguser from 'contexts/withOrguser';
import useParams from 'utils/useParams';

import TextArea from 'views/BdRequests/AddBdRequestComment/TextArea';
import CompanyLogo from 'components/CompanyLogo';
import LoadingRing from 'components/LoadingRing';
import PersonAvatar from 'components/PersonAvatar';
import SlackLogo from 'components/SlackLogo';

import { Mention } from '../Comment';
import { formatMentions } from '../utils';

import type { RequestParams } from './NewQuestionBdRequest';

type Props = {
  partners: Partner[],
  account: Account,
  partnerAccounts: PartnerAccount[],
  selectedQuestions: BdRequestQuestion[],
  customQuestions: string[],
  answers: string[],
  edit: () => void,
  reset: () => void,
  loggedInOrg: Org,
  loggedInOrguser: OrgUser,
  inAccountDetail?: boolean,
};

const Summary = ({
  partners,
  account,
  partnerAccounts,
  selectedQuestions,
  customQuestions,
  answers,
  edit,
  reset,
  loggedInOrg,
  loggedInOrguser,
  inAccountDetail,
}: Props) => {
  const [{ source }]: [RequestParams] = useParams({});
  const history = useHistory();

  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);

  const [createdRequests, setCreatedRequests] = useState<BdRequest[] | null>(null);
  const [submitting, setSubmitting] = useState(false);

  const canBeAskedCustomQuestions = useMemo(
    () => partners && partners.length === 1 && partners.every((p) => p.canBeAskedCustomQuestions),
    [partners]
  );
  const send = () => {
    const promises = partners.map((partner, index) => {
      itly.userClicksMessage({
        context: {
          message,
          selected_questions: selectedQuestions,
          answers,
          custom_questions: customQuestions,
          account_id: account.id,
          partner_id: partner.id,
        },
        in_slack: false,
        organization_id: loggedInOrg.id,
        partner_org_id: partner.partnerOrg.id,
        type: 'external',
      });
      return (
        partnerAccounts[index] &&
        createQuestionsBdRequest(
          account.id,
          partner.id,
          partnerAccounts[index].id,
          message,
          selectedQuestions.map((q) => q.id),
          answers,
          customQuestions,
          source,
          canBeAskedCustomQuestions
        )
      );
    });
    setSubmitting(true);
    Promise.all(promises)
      .then((requests) => {
        setSubmitting(false);
        if (requests.length === 1) {
          history.push(`/requests/${requests[0].id}`);
        } else {
          setCreatedRequests(requests);
        }
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    getBdRequestMessageSummary(
      partners.length === 1 ? partners[0].name : '*Partner name*',
      selectedQuestions.map((q) => q.text),
      customQuestions,
      answers
    )
      .then((summary) => {
        setMessage(summary);
        setLoading(false);
      })
      .then(() => {
        setLoading(false);
      });
  }, [partners, selectedQuestions, customQuestions, answers]);

  if (submitting || loading) {
    return (
      <div
        className={classNames(
          'card card-body p-3 flex-grow-0',
          !inAccountDetail && 'mx-auto mt-4 col-lg-7 col-md-9 col-sm-11'
        )}
      >
        <LoadingRing className="m-5" />
      </div>
    );
  }

  if (createdRequests) {
    const waitingApprovalCount = createdRequests.filter((r) => r.pendingApproval).length;
    return (
      <div
        className={classNames(
          'card card-body flex-grow-0 p-5 d-flex flex-column gap-10 align-items-center justify-content-center',
          !inAccountDetail && 'mx-auto mt-4 col-lg-7 col-md-9 col-sm-11'
        )}
      >
        <FontAwesomeIcon icon="check-circle" className="green" size="3x" />
        <h5 className="bold gray-700 m-0">
          {waitingApprovalCount > 0
            ? 'Requests sent, waiting for approval'
            : 'Your partners received your requests!'}
        </h5>
        {waitingApprovalCount > 0 && (
          <span className="large-text semi-bold gray-400">
            {waitingApprovalCount} {waitingApprovalCount === 1 ? 'request is' : 'requests are'}{' '}
            waiting for partner manager&apos;s approval
          </span>
        )}
        <Link className="btn btn-primary" to="/requests">
          Go to Requests page
        </Link>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        'card card-body p-3 flex-grow-0',
        !inAccountDetail && 'mx-auto mt-4 col-lg-7 col-md-9 col-sm-11'
      )}
    >
      <div className="d-flex flex-column gap-15">
        <span className="large-text bold gray-700">Request Summary</span>
        <div className="d-flex flex-row gap-20">
          <span className="normal-text semi-bold gray-600">To:</span>
          <div className="flex-fill d-flex flex-row align-items-center gap-15 flex-wrap">
            {partners.map((partner, index) => (
              <div
                key={partner.id + partnerAccounts[index].id}
                className="d-flex flex-row align-items-center gap-10"
              >
                {partner.slackChannel ? (
                  <SlackLogo height={20} width={20} />
                ) : (
                  <CompanyLogo
                    className=""
                    name={partner.name}
                    logoUrl={partner.logoUrl}
                    domain={partner.domain}
                    size={20}
                  />
                )}
                <span className="large-text bold gray-700">
                  {partner.slackChannel
                    ? `#${partner.slackChannel.name} (${partner.name})`
                    : partner.name}
                </span>
              </div>
            ))}
          </div>
        </div>
        <hr className="m-0" />
        <span className="normal-text semi-bold gray-600">Message:</span>
        <div className="d-flex flex-row gap-15">
          <div>
            <PersonAvatar
              size={36}
              firstName={loggedInOrguser.firstName}
              lastName={loggedInOrguser.lastName}
              imageUrl={loggedInOrguser.avatarImage}
            />
          </div>
          <div className="d-flex flex-column gap-5">
            <div className="d-flex flex-row align-items-center gap-5">
              <span className="large-text bold gray-700">{loggedInOrguser.fullName}</span>
              <span className="normal-text semi-bold gray-400">{moment().format('h:mm a')}</span>
            </div>
            <span className="normal-text gray-600" style={{ whiteSpace: 'pre-line' }}>
              {canBeAskedCustomQuestions && (
                <TextArea
                  isShared
                  value={message}
                  partnerId={partners[0]?.id}
                  partnerAccountId={partnerAccounts[0]?.id}
                  onChange={(e) => setMessage(e.target.value)}
                />
              )}
              {!canBeAskedCustomQuestions &&
                (typeof message === 'string'
                  ? formatMentions(message, (name, i, userId) => (
                      <Mention text={name} to={`/network/oid/${userId}`} key={userId} />
                    ))
                  : message)}
              {}
            </span>
          </div>
        </div>
        <div className="d-flex flex-row align-items-center gap-15">
          <UncontrolledPopover
            className="PopoverCustom"
            innerClassName="card-body px-3"
            target="reset-form"
            trigger="click"
            delay={{ show: 100, hide: 200 }}
          >
            <div className="d-flex flex-column gap-15" style={{ maxWidth: 250 }}>
              <span className="normal-text bold gray-700">
                Are you sure that you want to cancel and reset this request?
              </span>
              <div>
                <button type="button" className="btn btn-danger" onClick={reset}>
                  Confirm
                </button>
              </div>
            </div>
          </UncontrolledPopover>
          <button id="reset-form" type="button" className="btn btn-secondary">
            Cancel
          </button>
          <div className="flex-1" />
          <button type="button" className="btn btn-secondary" onClick={edit}>
            Edit
          </button>
          <button type="button" className="btn btn-primary" onClick={send}>
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

Summary.defaultProps = {
  inAccountDetail: false,
};

export default withOrguser(Summary);
